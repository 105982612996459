<template>
  <div v-show="isCheckoutSucceed" class="success-block">
    <div style="width: 60%">
      <Heading type="h3" text-align="center" class="mb-4"> Thank you! </Heading>
      Payment completed successfully. Now you can view purchased codes or redeem
      tickets. We have sent the same info to email used during purchase.
      <Preloader :loading="isOrderTokenLoading" />
      <Button @click="generateOrderToken" class="pa-3 mt-10">
        Go to see my order
      </Button>
      <div v-show="OrderTokenErr && OrderTokenErr.length">
        {{ OrderTokenErr }}
      </div>
    </div>
  </div>

  <div v-show="!isCheckoutSucceed">
    <div class="checkout padding-x">
      <Heading
        noDecoration
        type="h3"
        text-align="center"
        class="checkout__title"
      >
        Tickets
      </Heading>
      <Preloader :loading="isTicketsLoading" />
      <div class="checkout__tickets">
        <TicketCard
          v-for="(ticket, index) in tickets"
          :key="ticket.id"
          v-show="!ticket.is_sponsor"
          :ticket="ticket"
          :is-active="index === 0"
          :defaultQuantity="selectedTicket === ticket.id"
          is-checkout
          @change-quantity="ticketsQuantity[ticket.id] = $event"
        />
      </div>
    </div>
    <CheckoutForm
      v-show="hasTicketsSelected"
      :tickets-quantity="ticketsQuantity"
      :tickets-prices="ticketsPrices"
      @payment-success="proceedToSuccess"
    />
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
import Button from "@/components/common/Button";
import TicketCard from "@/components/common/TicketCard";
import CheckoutForm from "./CheckoutForm";
import { mapActions } from "vuex";

export default {
  name: "Checkout",
  props: {},
  components: {
    Preloader,
    Heading,
    Button,
    TicketCard,
    CheckoutForm,
  },
  data: () => ({
    isTicketsLoading: false,
    tickets: [],
    ticketsPrices: {},
    ticketsQuantity: {},
    isCheckoutSucceed: false,
    orderId: "",
    isOrderTokenLoading: false,
    OrderTokenErr: null,
  }),
  computed: {
    selectedTicket() {
      return this.$route.params.ticket;
    },
    hasTicketsSelected() {
      return (
        this.$route.params.ticket ||
        Object.values(this.ticketsQuantity).find((val) => val > 0)
      );
    },
  },
  mounted() {
    this.isTicketsLoading = true;
    this.getTickets();
  },
  methods: {
    ...mapActions(["GET"]),
    proceedToSuccess(id) {
      this.isCheckoutSucceed = true;
      this.orderId = id;
    },
    getTickets() {
      return this.GET({
        route: `ticketing/public/event/${this.$route.params.id}/ticket_type`,
      })
        .then((resp) => {
          let quantity = {};
          let prices = {};
          resp.data.map((i) => {
            prices[i.id] = i.price / 100;
            quantity[i.id] = 0;
          });
          this.tickets = resp.data;
          this.ticketsPrices = prices;
          this.ticketsQuantity = quantity;
          if (this.$route.params.ticket) {
            this.ticketsQuantity[this.$route.params.ticket] = 1;
          }
          this.isTicketsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isTicketsLoading = false;
        });
    },
    generateOrderToken() {
      if (!this.orderId) return;
      this.isOrderTokenLoading = true;
      this.GET({
        formRoute: false,
        route: `/ticketing/public/event/${this.$route.params.id}/order/${this.orderId}/generate_url`,
      })
        .then((res) => {
          this.isOrderTokenLoading = false;
          this.$router.replace(
            `/${this.$route.params.id}/tickets/${res.body.data.url}`
          );
        })
        .catch(() => {
          this.OrderTokenErr =
            "Sorry, error occurred. Check your email to see direct link to the page with order";
          this.isOrderTokenLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.success-block {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  button {
    margin: 0 auto;
  }
  @media (min-width: $media-sm) {
    padding-top: 140px;
  }
  @media (min-width: $media-md) {
    height: 50vh;
  }
}

.checkout {
  padding-top: 108px;

  &__tickets {
    padding-top: 60px;
    margin-bottom: 50px;
    display: grid;
    grid-gap: 60px 0;
  }

  @media (min-width: $media-sm) {
    padding-top: 140px;

    &__tickets {
      margin-bottom: 136px;
      grid-gap: 60px 24px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
